/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9ccc7148-92fa-4907-8902-ed3e33fd1406",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_R5TU7hJ79",
    "aws_user_pools_web_client_id": "2qkt8ll1rbmboiuphq14jlmt0m",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://jei4f4dy7vhjzjxkxexmnawon4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "bdsi-volunteer-photos164027-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
