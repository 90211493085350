import '@elastic/eui/dist/eui_theme_light.css';
import React, { useState, useEffect, useMemo } from 'react';
import { API,Storage  } from 'aws-amplify';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import Auth from '@aws-amplify/auth';
import logo from './logo.png';
import movemorelogo from './movemorelogo.png'
import moment from 'moment';
import DataTable from 'react-data-table-component';
import CsvLink from 'react-csv-export';

import {
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiPageTemplate,
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiFilePicker,
  EuiSelect,
  EuiSpacer,
  EuiDatePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiCallOut,
  EuiPanel,
  EuiSideNav,
  EuiBadge,
  EuiButtonIcon,
  EuiPopover,
  EuiText,
  EuiLink,
  EuiFieldNumber,
  EuiTabs,
  EuiTab,
  EuiContextMenuPanel,
  EuiCheckboxGroup,
  EuiContextMenuItem,
  EuiTextArea
} from '@elastic/eui';

const categoryOptions = [
  {"text": "No Poverty", "value": "NoPoverty"},
  {"text": "Zero Hunger", "value": "ZeroHunger"},
  {"text": "Good Health and Well-Being", "value": "GoodHealthWellBeing"},
  {"text": "Quality Education", "value": "QualityEducation"},
  {"text": "Gender Equality", "value": "GenderEquality"},
  {"text": "Clean Water and Sanitation", "value": "CleanWaterSanitation"},
  {"text": "Affordable and Clean Energy", "value": "AffordableCleanEnergy"},
  {"text": "Decent Work and Economic Growth", "value": "DecentWorkEconomicGrowth"},
  {"text": "Industry, Innovation and Infrastructure", "value": "IndustryInnovationInfrastructure"},
  {"text": "Reduced Inequalities", "value": "ReducedInequalities"},
  {"text": "Sustainable Cities and Communities", "value": "SustainableCitiesCommunities"},
  {"text": "Responsible Consumption and Production", "value": "ResponsibleConsumptionProduction"},
  {"text": "Climate Action", "value": "ClimateAction"},
  {"text": "Life Below Water", "value": "LifeBelowWater"},
  {"text": "Life On Land", "value": "LifeOnLand"},
  {"text": "Peace, Justice, and Strong Institutions", "value": "PeaceJusticeStrongInstitutions"},
  {"text": "Partnerships For The Goals", "value": "PartnershipsForTheGoals"}
]

const countryOptions = [
  {"text": "Albania", "value": "AL"},
  {"text": "Åland Islands", "value": "AX"},
  {"text": "Algeria", "value": "DZ"},
  {"text": "American Samoa", "value": "AS"},
  {"text": "Andorra", "value": "AD"},
  {"text": "Angola", "value": "AO"},
  {"text": "Anguilla", "value": "AI"},
  {"text": "Antarctica", "value": "AQ"},
  {"text": "Antigua and Barbuda", "value": "AG"},
  {"text": "Argentina", "value": "AR"},
  {"text": "Armenia", "value": "AM"},
  {"text": "Aruba", "value": "AW"},
  {"text": "Australia", "value": "AU"},
  {"text": "Austria", "value": "AT"},
  {"text": "Azerbaijan", "value": "AZ"},
  {"text": "Bahamas (the)", "value": "BS"},
  {"text": "Bahrain", "value": "BH"},
  {"text": "Bangladesh", "value": "BD"},
  {"text": "Barbados", "value": "BB"},
  {"text": "Belarus", "value": "BY"},
  {"text": "Belgium", "value": "BE"},
  {"text": "Belize", "value": "BZ"},
  {"text": "Benin", "value": "BJ"},
  {"text": "Bermuda", "value": "BM"},
  {"text": "Bhutan", "value": "BT"},
  {"text": "Bolivia (Plurinational State of)", "value": "BO"},
  {"text": "Bonaire, Sint Eustatius and Saba", "value": "BQ"},
  {"text": "Bosnia and Herzegovina", "value": "BA"},
  {"text": "Botswana", "value": "BW"},
  {"text": "Bouvet Island", "value": "BV"},
  {"text": "Brazil", "value": "BR"},
  {"text": "British Indian Ocean Territory (the)", "value": "IO"},
  {"text": "Brunei Darussalam", "value": "BN"},
  {"text": "Bulgaria", "value": "BG"},
  {"text": "Burkina Faso", "value": "BF"},
  {"text": "Burundi", "value": "BI"},
  {"text": "Cabo Verde", "value": "CV"},
  {"text": "Cambodia", "value": "KH"},
  {"text": "Cameroon", "value": "CM"},
  {"text": "Canada", "value": "CA"},
  {"text": "Cayman Islands (the)", "value": "KY"},
  {"text": "Central African Republic (the)", "value": "CF"},
  {"text": "Chad", "value": "TD"},
  {"text": "Chile", "value": "CL"},
  {"text": "China", "value": "CN"},
  {"text": "Christmas Island", "value": "CX"},
  {"text": "Cocos (Keeling) Islands (the)", "value": "CC"},
  {"text": "Colombia", "value": "CO"},
  {"text": "Comoros (the)", "value": "KM"},
  {"text": "Congo (the Democratic Republic of the)", "value": "CD"},
  {"text": "Congo (the)", "value": "CG"},
  {"text": "Cook Islands (the)", "value": "CK"},
  {"text": "Costa Rica", "value": "CR"},
  {"text": "Croatia", "value": "HR"},
  {"text": "Cuba", "value": "CU"},
  {"text": "Curaçao", "value": "CW"},
  {"text": "Cyprus", "value": "CY"},
  {"text": "Czechia", "value": "CZ"},
  {"text": "Côte d'Ivoire", "value": "CI"},
  {"text": "Denmark", "value": "DK"},
  {"text": "Djibouti", "value": "DJ"},
  {"text": "Dominica", "value": "DM"},
  {"text": "Dominican Republic (the)", "value": "DO"},
  {"text": "Ecuador", "value": "EC"},
  {"text": "Egypt", "value": "EG"},
  {"text": "El Salvador", "value": "SV"},
  {"text": "Equatorial Guinea", "value": "GQ"},
  {"text": "Eritrea", "value": "ER"},
  {"text": "Estonia", "value": "EE"},
  {"text": "Eswatini", "value": "SZ"},
  {"text": "Ethiopia", "value": "ET"},
  {"text": "Falkland Islands (the) [Malvinas]", "value": "FK"},
  {"text": "Faroe Islands (the)", "value": "FO"},
  {"text": "Fiji", "value": "FJ"},
  {"text": "Finland", "value": "FI"},
  {"text": "France", "value": "FR"},
  {"text": "French Guiana", "value": "GF"},
  {"text": "French Polynesia", "value": "PF"},
  {"text": "French Southern Territories (the)", "value": "TF"},
  {"text": "Gabon", "value": "GA"},
  {"text": "Gambia (the)", "value": "GM"},
  {"text": "Georgia", "value": "GE"},
  {"text": "Germany", "value": "DE"},
  {"text": "Ghana", "value": "GH"},
  {"text": "Gibraltar", "value": "GI"},
  {"text": "Greece", "value": "GR"},
  {"text": "Greenland", "value": "GL"},
  {"text": "Grenada", "value": "GD"},
  {"text": "Guadeloupe", "value": "GP"},
  {"text": "Guam", "value": "GU"},
  {"text": "Guatemala", "value": "GT"},
  {"text": "Guernsey", "value": "GG"},
  {"text": "Guinea", "value": "GN"},
  {"text": "Guinea-Bissau", "value": "GW"},
  {"text": "Guyana", "value": "GY"},
  {"text": "Haiti", "value": "HT"},
  {"text": "Heard Island and McDonald Islands", "value": "HM"},
  {"text": "Holy See (the)", "value": "VA"},
  {"text": "Honduras", "value": "HN"},
  {"text": "Hong Kong", "value": "HK"},
  {"text": "Hungary", "value": "HU"},
  {"text": "Iceland", "value": "IS"},
  {"text": "India", "value": "IN"},
  {"text": "Indonesia", "value": "ID"},
  {"text": "Iran (Islamic Republic of)", "value": "IR"},
  {"text": "Iraq", "value": "IQ"},
  {"text": "Ireland", "value": "IE"},
  {"text": "Isle of Man", "value": "IM"},
  {"text": "Israel", "value": "IL"},
  {"text": "Italy", "value": "IT"},
  {"text": "Jamaica", "value": "JM"},
  {"text": "Japan", "value": "JP"},
  {"text": "Jersey", "value": "JE"},
  {"text": "Jordan", "value": "JO"},
  {"text": "Kazakhstan", "value": "KZ"},
  {"text": "Kenya", "value": "KE"},
  {"text": "Kiribati", "value": "KI"},
  {"text": "Korea (the Democratic People's Republic of)", "value": "KP"},
  {"text": "Korea (the Republic of)", "value": "KR"},
  {"text": "Kuwait", "value": "KW"},
  {"text": "Kyrgyzstan", "value": "KG"},
  {"text": "Lao People's Democratic Republic (the)", "value": "LA"},
  {"text": "Latvia", "value": "LV"},
  {"text": "Lebanon", "value": "LB"},
  {"text": "Lesotho", "value": "LS"},
  {"text": "Liberia", "value": "LR"},
  {"text": "Libya", "value": "LY"},
  {"text": "Liechtenstein", "value": "LI"},
  {"text": "Lithuania", "value": "LT"},
  {"text": "Luxembourg", "value": "LU"},
  {"text": "Macao", "value": "MO"},
  {"text": "Madagascar", "value": "MG"},
  {"text": "Malawi", "value": "MW"},
  {"text": "Malaysia", "value": "MY"},
  {"text": "Maldives", "value": "MV"},
  {"text": "Mali", "value": "ML"},
  {"text": "Malta", "value": "MT"},
  {"text": "Marshall Islands (the)", "value": "MH"},
  {"text": "Martinique", "value": "MQ"},
  {"text": "Mauritania", "value": "MR"},
  {"text": "Mauritius", "value": "MU"},
  {"text": "Mayotte", "value": "YT"},
  {"text": "Mexico", "value": "MX"},
  {"text": "Micronesia (Federated States of)", "value": "FM"},
  {"text": "Moldova (the Republic of)", "value": "MD"},
  {"text": "Monaco", "value": "MC"},
  {"text": "Mongolia", "value": "MN"},
  {"text": "Montenegro", "value": "ME"},
  {"text": "Montserrat", "value": "MS"},
  {"text": "Morocco", "value": "MA"},
  {"text": "Mozambique", "value": "MZ"},
  {"text": "Myanmar", "value": "MM"},
  {"text": "Namibia", "value": "NA"},
  {"text": "Nauru", "value": "NR"},
  {"text": "Nepal", "value": "NP"},
  {"text": "Netherlands (the)", "value": "NL"},
  {"text": "New Caledonia", "value": "NC"},
  {"text": "New Zealand", "value": "NZ"},
  {"text": "Nicaragua", "value": "NI"},
  {"text": "Niger (the)", "value": "NE"},
  {"text": "Nigeria", "value": "NG"},
  {"text": "Niue", "value": "NU"},
  {"text": "Norfolk Island", "value": "NF"},
  {"text": "Northern Mariana Islands (the)", "value": "MP"},
  {"text": "Norway", "value": "NO"},
  {"text": "Oman", "value": "OM"},
  {"text": "Pakistan", "value": "PK"},
  {"text": "Palau", "value": "PW"},
  {"text": "Palestine, State of", "value": "PS"},
  {"text": "Panama", "value": "PA"},
  {"text": "Papua New Guinea", "value": "PG"},
  {"text": "Paraguay", "value": "PY"},
  {"text": "Peru", "value": "PE"},
  {"text": "Philippines (the)", "value": "PH"},
  {"text": "Pitcairn", "value": "PN"},
  {"text": "Poland", "value": "PL"},
  {"text": "Portugal", "value": "PT"},
  {"text": "Puerto Rico", "value": "PR"},
  {"text": "Qatar", "value": "QA"},
  {"text": "Republic of North Macedonia", "value": "MK"},
  {"text": "Romania", "value": "RO"},
  {"text": "Russian Federation (the)", "value": "RU"},
  {"text": "Rwanda", "value": "RW"},
  {"text": "Réunion", "value": "RE"},
  {"text": "Saint Barthélemy", "value": "BL"},
  {"text": "Saint Helena, Ascension and Tristan da Cunha", "value": "SH"},
  {"text": "Saint Kitts and Nevis", "value": "KN"},
  {"text": "Saint Lucia", "value": "LC"},
  {"text": "Saint Martin (French part)", "value": "MF"},
  {"text": "Saint Pierre and Miquelon", "value": "PM"},
  {"text": "Saint Vincent and the Grenadines", "value": "VC"},
  {"text": "Samoa", "value": "WS"},
  {"text": "San Marino", "value": "SM"},
  {"text": "Sao Tome and Principe", "value": "ST"},
  {"text": "Saudi Arabia", "value": "SA"},
  {"text": "Senegal", "value": "SN"},
  {"text": "Serbia", "value": "RS"},
  {"text": "Seychelles", "value": "SC"},
  {"text": "Sierra Leone", "value": "SL"},
  {"text": "Singapore", "value": "SG"},
  {"text": "Sint Maarten (Dutch part)", "value": "SX"},
  {"text": "Slovakia", "value": "SK"},
  {"text": "Slovenia", "value": "SI"},
  {"text": "Solomon Islands", "value": "SB"},
  {"text": "Somalia", "value": "SO"},
  {"text": "South Africa", "value": "ZA"},
  {"text": "South Georgia and the South Sandwich Islands", "value": "GS"},
  {"text": "South Sudan", "value": "SS"},
  {"text": "Spain", "value": "ES"},
  {"text": "Sri Lanka", "value": "LK"},
  {"text": "Sudan (the)", "value": "SD"},
  {"text": "Suritext", "value": "SR"},
  {"text": "Svalbard and Jan Mayen", "value": "SJ"},
  {"text": "Sweden", "value": "SE"},
  {"text": "Switzerland", "value": "CH"},
  {"text": "Syrian Arab Republic", "value": "SY"},
  {"text": "Taiwan (Province of China)", "value": "TW"},
  {"text": "Tajikistan", "value": "TJ"},
  {"text": "Tanzania, United Republic of", "value": "TZ"},
  {"text": "Thailand", "value": "TH"},
  {"text": "Timor-Leste", "value": "TL"},
  {"text": "Togo", "value": "TG"},
  {"text": "Tokelau", "value": "TK"},
  {"text": "Tonga", "value": "TO"},
  {"text": "Trinidad and Tobago", "value": "TT"},
  {"text": "Tunisia", "value": "TN"},
  {"text": "Turkey", "value": "TR"},
  {"text": "Turkmenistan", "value": "TM"},
  {"text": "Turks and Caicos Islands (the)", "value": "TC"},
  {"text": "Tuvalu", "value": "TV"},
  {"text": "Uganda", "value": "UG"},
  {"text": "Ukraine", "value": "UA"},
  {"text": "United Arab Emirates (the)", "value": "AE"},
  {"text": "United Kingdom of Great Britain and Northern Ireland (the)", "value": "GB"},
  {"text": "United States Minor Outlying Islands (the)", "value": "UM"},
  {"text": "United States of America (the)", "value": "US"},
  {"text": "Uruguay", "value": "UY"},
  {"text": "Uzbekistan", "value": "UZ"},
  {"text": "Vanuatu", "value": "VU"},
  {"text": "Venezuela (Bolivarian Republic of)", "value": "VE"},
  {"text": "Viet Nam", "value": "VN"},
  {"text": "Virgin Islands (British)", "value": "VG"},
  {"text": "Virgin Islands (U.S.)", "value": "VI"},
  {"text": "Wallis and Futuna", "value": "WF"},
  {"text": "Western Sahara", "value": "EH"},
  {"text": "Yemen", "value": "YE"},
  {"text": "Zambia", "value": "ZM"},
  {"text": "Zimbabwe", "value": "ZW"}
];

const stateOptions = [
  {
    "text": "Alabama",
    "value": "AL"
  },
  {
    "text": "Alaska",
    "value": "AK"
  },
  {
    "text": "American Samoa",
    "value": "AS"
  },
  {
    "text": "Arizona",
    "value": "AZ"
  },
  {
    "text": "Arkansas",
    "value": "AR"
  },
  {
    "text": "California",
    "value": "CA"
  },
  {
    "text": "Colorado",
    "value": "CO"
  },
  {
    "text": "Connecticut",
    "value": "CT"
  },
  {
    "text": "Delaware",
    "value": "DE"
  },
  {
    "text": "District Of Columbia",
    "value": "DC"
  },
  {
    "text": "Federated States Of Micronesia",
    "value": "FM"
  },
  {
    "text": "Florida",
    "value": "FL"
  },
  {
    "text": "Georgia",
    "value": "GA"
  },
  {
    "text": "Guam",
    "value": "GU"
  },
  {
    "text": "Hawaii",
    "value": "HI"
  },
  {
    "text": "Idaho",
    "value": "ID"
  },
  {
    "text": "Illinois",
    "value": "IL"
  },
  {
    "text": "Indiana",
    "value": "IN"
  },
  {
    "text": "Iowa",
    "value": "IA"
  },
  {
    "text": "Kansas",
    "value": "KS"
  },
  {
    "text": "Kentucky",
    "value": "KY"
  },
  {
    "text": "Louisiana",
    "value": "LA"
  },
  {
    "text": "Maine",
    "value": "ME"
  },
  {
    "text": "Marshall Islands",
    "value": "MH"
  },
  {
    "text": "Maryland",
    "value": "MD"
  },
  {
    "text": "Massachusetts",
    "value": "MA"
  },
  {
    "text": "Michigan",
    "value": "MI"
  },
  {
    "text": "Minnesota",
    "value": "MN"
  },
  {
    "text": "Mississippi",
    "value": "MS"
  },
  {
    "text": "Missouri",
    "value": "MO"
  },
  {
    "text": "Montana",
    "value": "MT"
  },
  {
    "text": "Nebraska",
    "value": "NE"
  },
  {
    "text": "Nevada",
    "value": "NV"
  },
  {
    "text": "New Hampshire",
    "value": "NH"
  },
  {
    "text": "New Jersey",
    "value": "NJ"
  },
  {
    "text": "New Mexico",
    "value": "NM"
  },
  {
    "text": "New York",
    "value": "NY"
  },
  {
    "text": "North Carolina",
    "value": "NC"
  },
  {
    "text": "North Dakota",
    "value": "ND"
  },
  {
    "text": "Northern Mariana Islands",
    "value": "MP"
  },
  {
    "text": "Ohio",
    "value": "OH"
  },
  {
    "text": "Oklahoma",
    "value": "OK"
  },
  {
    "text": "Oregon",
    "value": "OR"
  },
  {
    "text": "Palau",
    "value": "PW"
  },
  {
    "text": "Pennsylvania",
    "value": "PA"
  },
  {
    "text": "Puerto Rico",
    "value": "PR"
  },
  {
    "text": "Rhode Island",
    "value": "RI"
  },
  {
    "text": "South Carolina",
    "value": "SC"
  },
  {
    "text": "South Dakota",
    "value": "SD"
  },
  {
    "text": "Tennessee",
    "value": "TN"
  },
  {
    "text": "Texas",
    "value": "TX"
  },
  {
    "text": "Utah",
    "value": "UT"
  },
  {
    "text": "Vermont",
    "value": "VT"
  },
  {
    "text": "Virgin Islands",
    "value": "VI"
  },
  {
    "text": "Virginia",
    "value": "VA"
  },
  {
    "text": "Washington",
    "value": "WA"
  },
  {
    "text": "West Virginia",
    "value": "WV"
  },
  {
    "text": "Wisconsin",
    "value": "WI"
  },
  {
    "text": "Wyoming",
    "value": "WY"
  }
];

const tshirtOptions = [
  {value: "No-Shirt", text: "No Shirt"}
];

let partySizeOptions = [
];

for(let i=1;i<31;i++) {
  partySizeOptions.push({value: i.toString(), text: i.toString()});
}


async function logOut() {
  await Auth.signOut(); 
  window.location.reload();
}

function Admin({volunteers, setVolunteers, selectedTabId}) { 
  const [filterText, setFilterText] = useState('');
  const [filterType, setFilterType] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredVolunteers = useMemo(() => {
    let newVolunteers = volunteers;
    if(filterType === 'CompletedNoTshirt') {
      newVolunteers = volunteers.filter(item => item.volunteerStatus && item.volunteerStatus === "complete" && !item.tshirtShipped);
    } else if(filterType === 'CompletedShippedTshirt') {
      newVolunteers = volunteers.filter(item => item.volunteerStatus && item.volunteerStatus === "complete" && item.tshirtShipped);
    } else if(filterType === 'CompletedPhonetool') {
      newVolunteers = volunteers.filter(item => item.volunteerStatus && item.volunteerStatus === "complete" && item.phonetoolGiven);
    } else if(filterType === 'CompletedNoPhonetool') {
      newVolunteers = volunteers.filter(item => item.volunteerStatus && item.volunteerStatus === "complete" && !item.phonetoolGiven);
    } else if(filterType === 'NotCompleted') {
      newVolunteers = volunteers.filter(item => item.volunteerStatus && item.volunteerStatus === "planned");
    }
    if(selectedTabId) {
      newVolunteers = newVolunteers.filter(item => item.eventID === selectedTabId);
    }
    return newVolunteers.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
  }, [filterText,filterType,volunteers]);
  
	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
		  if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
		  }
		};
		return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} colName='Name'/>;
	}, [filterText, resetPaginationToggle]);
  
  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      maxWidth: '7%'
    },
    {
      name: 'Alias',
      selector: row => row.alias,
      sortable: true,
      maxWidth: '7%',
      cell: row => <a href={"https://phonetool.amazon.com/users/"+row.alias}>@{row.alias}</a>
    },
    {
      name: 'Address',
      selector: row => row.address1,
      sortable: true,
      maxWidth: '10%',
      wrap: true,
      cell: row => {
        return (
          <div>
          {row.address1}
          {row.address2 && <EuiSpacer size="xs"/>}
          {row.address2}
          <EuiSpacer size="xs"/>
          {row.city} {row.state} {row.zipcode} {row.country}
          </div>
        );
      }
    },
    {
      name: 'Status',
      selector: row => row.volunteerStatus,
      sortable: true,
      maxWidth: '5%',
      cell: row => {
        return row.volunteerStatus.charAt(0).toUpperCase() + row.volunteerStatus.slice(1);
      }
    },
    {
      name: 'Volunteer Org',
      selector: row => row.org,
      sortable: true,
      maxWidth: '15%',
      wrap: true
    },
    {
      name: 'Volunteer Activity',
      selector: row => row.activity,
      sortable: true,
      maxWidth: '7%',
      wrap: true
    },
    {
      name: 'Volunteer Activity Category',
      selector: row => row.category,
      sortable: true,
      maxWidth: '7%',
      wrap: true
    },
    {
      name: 'Type',
      selector: row => row.volunteerType,
      sortable: true,
      maxWidth: '5%',
      cell: row=> {
        if(row.volunteerType === "one") {
          return "Individual";
        } else if(row.volunteerType === "team") {
          return "Team";
        }
      }
    },
    {
      name: 'Size',
      selector: row => row.tshirtSize,
      sortable: true,
      maxWidth: '5%'
    },
    {
      name: 'T-Shirt',
      selector: row => row.tshirtShipped,
      sortable: true,
      maxWidth: '7%',
      cell: row=> 
      <EuiSelect
        userid={row.alias}
        options={[{"text":"Yes","value":true},{"text":"No","value":false}]}
        value={row.tshirtShipped}
        onChange={async (e) => {
          const newVolunteers = volunteers.map(p =>
            p.alias === e.target.attributes.userid.value
              ? { ...p, tshirtShipped: (e.target.value === "true") }
              : p
          );
          setVolunteers(newVolunteers);
          await API.graphql({ query: mutations.updateVolunteer, variables: { input: {id: e.target.attributes.userid.value, 'tshirtShipped': (e.target.value === "true")}} });
        }}
      />
    },
    {
      name: 'Phonetool',
      selector: row => row.phonetoolGiven,
      sortable: true,
      maxWidth: '7%',
      cell: row=> 
      <EuiSelect
        userid={row.alias}
        options={[{"text":"Yes","value":true},{"text":"No","value":false}]}
        value={row.phonetoolGiven}
        onChange={async (e) => {
          const newVolunteers = volunteers.map(p =>
            p.alias === e.target.attributes.userid.value
              ? { ...p, phonetoolGiven: (e.target.value === "true") }
              : p
          );
          setVolunteers(newVolunteers);
          await API.graphql({ query: mutations.updateVolunteer, variables: { input: {id: e.target.attributes.userid.value, 'phonetoolGiven': (e.target.value === "true")}} });
        }}
      />
    },
    {
      name: 'CreatedAt',
      selector: row => row.createdAt,
      sortable: true,
      maxWidth: '7%',
      wrap: true
    }
  ]
  
  const sideNav = [
    {
      name: 'Volunteer Status',
      id: 0,
      items: [
        {
          name: 'Completed',
          id: 1,
          items: [
            {
              name: 'T-Shirt Shipped',
              id: 3,
              onClick: () => {setFilterType('CompletedShippedTshirt')}
            },
            {
              name: 'T-Shirt Not Shipped',
              id: 4,
              onClick: () => {setFilterType('CompletedNoTshirt')}
            },
            {
              name: 'Phonetool Given',
              id: 3,
              onClick: () => {setFilterType('CompletedPhonetool')}
            },
            {
              name: 'Phonetool Not Given',
              id: 4,
              onClick: () => {setFilterType('CompletedNoPhonetool')}
            }
          ]
        },
        {
          name: 'Planned',
          id: 2,
          onClick: () => {setFilterType('NotCompleted')}
        }
      ]
    }
  ];
  
  return (
    <div>
    <EuiPageTemplate
      pageSideBar={<EuiSideNav
        style={{ width: 192 }}
        items={sideNav}
      />}
      restrictWidth="100%"
      pageHeader={{
        iconType: 'managementApp',
        pageTitle: 'Admin'
      }}>
      <CsvLink data={filteredVolunteers} fileName="Volunteer_Export.csv">
        <EuiButton>Export CSV</EuiButton>
      </CsvLink>
      <EuiSpacer/>
      {(filterType === 'CompletedNoTshirt' || filterType === 'CompletedShippedTshirt' || filterType === 'CompletedPhonetool' || filterType === 'CompletedNoPhonetool') && <EuiBadge>Completed</EuiBadge>}
      {(filterType === 'CompletedNoTshirt') && <EuiBadge>Not Shipped</EuiBadge>}
      {(filterType === 'CompletedShippedTshirt') && <EuiBadge>Shipped</EuiBadge>}
      {(filterType === 'CompletedPhonetool') && <EuiBadge>Phonetool</EuiBadge>}
      {(filterType === 'CompletedNoPhonetool') && <EuiBadge>No PhoneTool</EuiBadge>}
      {(filterType === 'NotCompleted') && <EuiBadge>Planned</EuiBadge>}
      <DataTable
        noHeader
        title=''
        columns={columns}
        data={filteredVolunteers}
        striped
        dense
        pagination
        paginationPerPage='25'
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderAlign="left"
        subHeaderComponent={subHeaderComponentMemo}
        />
    </EuiPageTemplate>
    </div>
  );
}

function FilterComponent({ filterText, onFilter, onClear, colName }) {
	return(
		<div>
      <EuiFieldText placeholder={"Filter by "+colName} name="search" value={filterText} onChange={onFilter}/>
		</div>
	);
}

function VolunteerList({volunteers, selectedTabId, setSelectedTabId}) {
  const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredVolunteers = volunteers.filter(item => item.amznLoc && item.amznLoc.toLowerCase().includes(filterText.toLowerCase()) && item.eventID === selectedTabId);
  
	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
		  if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
		  }
		};
		return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} colName='Location'/>;
	}, [filterText, resetPaginationToggle]);
  
  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      maxWidth: '10%'
    },
    {
      name: 'Alias',
      selector: row => row.alias,
      sortable: true,
      maxWidth: '15%',
      cell: row => <a href={"https://phonetool.amazon.com/users/"+row.alias}>@{row.alias}</a>
    },
    {
      name: 'Amazon Location',
      selector: row => row.amznLoc,
      sortable: true,
      maxWidth: '15%'
    },
    {
      name: 'Volunteer Org',
      selector: row => row.org,
      sortable: true,
      maxWidth: '15%'
    },
    {
      name: 'Volunteer Activity',
      selector: row => row.activity,
      sortable: true,
      maxWidth: '7%'
    },
    {
      name: 'Volunteer Activity Category',
      selector: row => row.category,
      sortable: true,
      maxWidth: '7%'
    },
    {
      name: 'Volunteer Type',
      selector: row => row.volunteerType,
      sortable: true,
      maxWidth: '15%',
      cell: row=> {
        if(row.volunteerType === "one") {
          return "Individual";
        } else if(row.volunteerType === "team") {
          if(row.team) {
            return "Team (" + row.team.name + ")";
          } else {
            return "Team";
          }
        }
      }
    },
  ]
  
  return (
    <div>
    <EuiPageTemplate
      restrictWidth="75%"
      pageHeader={{
        iconType: 'searchProfilerApp',
        pageTitle: 'Volunteer List'
      }}>
      <EuiTabs>
        {/* <EuiTab
          onClick={() =>  {
            setSelectedTabId("Apr2024");
          }}
          isSelected={"Apr2024" === selectedTabId}
        >
          Volunteer Activity
        </EuiTab> */}
        <EuiTab
          onClick={() =>  setSelectedTabId("StepUp2024")}
          isSelected={"StepUp2024" === selectedTabId}
        >
          Move More Month
        </EuiTab>
        <EuiTab
          onClick={() =>  setSelectedTabId("CSMMoveMore2024")}
          isSelected={"CSMMoveMore2024" === selectedTabId}
        >
          Strategic CSMs Wellness initiative
        </EuiTab>
      </EuiTabs>
      <DataTable
        noHeader
        title=''
        columns={columns}
        data={filteredVolunteers}
        striped
        dense
        pagination
        paginationPerPage='25'
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderAlign="left"
        subHeaderComponent={subHeaderComponentMemo}
        />
    </EuiPageTemplate>
    </div>
  );
}

function SignUp({userData, selectedTabId, setSelectedTabId}) {
  const defaultVolunteerObject = {
    email: userData.email,
    name: userData['given_name']+" "+userData['family_name'],
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    tshirtSize: "No Shirt",
    volunteerStatus: "planned",
    volunteerType: "one",
    category: "",
    eventDate: (moment().toISOString().split("T"))[0],
    amznLoc: userData.address.formatted,
    alias:(userData['cognito:username'].split("_"))[1],
    tshirtShipped: false,
    phonetoolGiven: false,
    fileKey: "",
    country: "US",
    partySize: "1",
    activity: "",
    plannedActivityHours: 0,
    finalActivityHours: 0,
    charitySelection: "",
    activityTypes: "",
    plannedActivityUnit: "Miles",
    finalActivityUnit: "Miles"
  };

  const defaultTeamObject = {
    name: "",
    description: "",
    org: "",
    activity: "",
    category: "",
    eventDate: (moment().toISOString().split("T"))[0]
  };

  const queryParams = new URLSearchParams(window.location.search);
  const tabID = queryParams.get("tab");
  const teamID = queryParams.get("teamid");

  //const [selectedTabId, setSelectedTabId] = useState("");
  const [formInput, setFormInput] = useState(defaultVolunteerObject);

  const [teamFormInput,setTeamFormInput] = useState(defaultTeamObject);

  const [updateForm, setUpdateForm] = useState(false);
  const [formSuccess, setFormSuccess] = useState(0);
  const [fileUpload, setFileUpload] = useState(false);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [nonUS, setNonUS] = useState(false);
  const [openPopup,setOpenPopup] = useState(false);
  const [openEditPopup,setOpenEditPopup] = useState(false);
  const [selectionValue,setSelectionValue] = useState(formInput.teamID);
  const [statusError,setStatusError] = useState("");
  const [plannedUnitOpen,setPlannedUnitOpen] = useState(false);
  const [finalUnitOpen,setFinalUnitOpen] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [createTeamError, setCreateTeamError] = useState("");
  const [isTeamOwner, setIsTeamOwner] = useState(false);
  const [openSharePopup, setOpenSharePopup] = useState(false);
  const [openAddMemberPopup,setOpenAddMemberPopup] = useState(false);
  const [addTeamMembersInput, setAddTeamMembersInput] = useState("");
  const shareLink = "https://bdsivolunteer.sa.aws.dev/?tab="+selectedTabId+"&teamid="+selectionValue;

  async function getTeamList() {
    if(selectedTabId) {
      let eventIDfilter = {
          eventID: {
              eq: selectedTabId
          }
      };
      let results = await API.graphql({ query: queries.listTeams, variables: {limit: 1000, filter: eventIDfilter}});
      let _teamList =  results.data.listTeams.items.map(team => ({
        "value" : team.id,
        "key": team.id,
        "text" : team.name,
        "label" : team.name,
        "checked" : 'on',
        "description" : team.description,
        "org": team.org,
        "category": team.category,
        "activity": team.activity,
        "eventdate": team.eventDate,
        "owner": team.owner
      }));
      setTeamList(_teamList);
    }
  }
  
  async function getVolunteer() {
    if(selectedTabId) {
      try {
        let results = await API.graphql({ query: queries.getVolunteer, variables: { alias: userData['cognito:username'].split("_")[1], eventID: selectedTabId }});
        if(!results.data.getVolunteer) {
          results.data.getVolunteer = defaultVolunteerObject;
          results.data.getVolunteer.eventID = selectedTabId;
          setUpdateForm(false);
        } else {
          setUpdateForm(true);
        }
        if(!results.data.getVolunteer.plannedActivityUnit) {
          results.data.getVolunteer.plannedActivityUnit = "Miles";
        }
        if(!results.data.getVolunteer.finalActivityUnit) {
          results.data.getVolunteer.finalActivityUnit = "Miles";
        }
        delete results.data.getVolunteer.createdAt;
        delete results.data.getVolunteer.updatedAt;
        delete results.data.getVolunteer.owner;
        if(results.data.getVolunteer.country !== 'US') {
          setNonUS(true);
        }
        if(results.data.getVolunteer.volunteerType !== "team") {
          results.data.getVolunteer.teamID = "NoTeam";
        }
        if(results.data.getVolunteer.teamID !== "NoTeam") {
          let teamSelected = teamList.filter((team) => team.value === results.data.getVolunteer.teamID);
          if(teamSelected[0]) {
            let eventdate = teamSelected[0].eventdate;
            if(!eventdate) {
              eventdate = (moment().toISOString().split("T"))[0];
            }
            results.data.getVolunteer.org = teamSelected[0].org;
            results.data.getVolunteer.activity = teamSelected[0].activity;
            results.data.getVolunteer.category = teamSelected[0].category;
            results.data.getVolunteer.eventDate = eventdate;
          }
        }
        setFormInput(results.data.getVolunteer);
        setSelectionValue(results.data.getVolunteer.teamID);
      } catch(e) {
        console.log(e);
      }
    }
  }

  async function addTeamMembers() {
    if(addTeamMembersInput) {
      let arr = addTeamMembersInput.split(/[\n,]/);
      for(let i in arr) {
        try { 
          const currentSelectedTeam = (teamList.filter((team) => team.value === selectionValue));
          let newFormInput = defaultVolunteerObject;
          newFormInput.alias = arr[i];
          newFormInput.amznLoc = "Remote";
          newFormInput.name = arr[i];
          newFormInput.email = arr[i]+"@amazon.com";
          newFormInput.eventID = selectedTabId;
          newFormInput.volunteerType = "team";
          newFormInput.volunteerStatus = "complete";
          newFormInput.finalActivityUnit = formInput.finalActivityUnit;
          newFormInput.plannedActivityUnit = formInput.plannedActivityUnit;
          newFormInput.finalActivityHours = formInput.finalActivityHours;
          newFormInput.plannedActivityHours = formInput.plannedActivityHours;

          newFormInput.teamID = currentSelectedTeam[0].value;
          newFormInput.org = currentSelectedTeam[0].org;
          newFormInput.category = currentSelectedTeam[0].category;
          newFormInput.actvity = currentSelectedTeam[0].actvity;
          newFormInput.eventDate = currentSelectedTeam[0].eventDate;
          if(!currentSelectedTeam[0].eventDate) {
            newFormInput.eventDate = (moment().toISOString().split("T"))[0];
          }
          await API.graphql({ query: mutations.createVolunteer, variables: {input: newFormInput}});
        } catch(e) {
          console.log(e);
        }
      }
    }
    setOpenAddMemberPopup(false);
  }

  async function editTeam() {
    try { 
      teamFormInput.owner = userData['cognito:username'];
      teamFormInput.eventID = selectedTabId;
      let apiData = await API.graphql({ query: mutations.updateTeam, variables: { input: teamFormInput } });
      getTeamList();
      setOpenEditPopup(false);
    } catch(e) {
      console.log(e);
    }
  }

  async function createTeam() {
    const ownedTeam = (teamList.filter((team) => team.owner === userData['cognito:username']));
    if(!teamFormInput.org || !teamFormInput.category || ! teamFormInput.activity) {
      let missingFields = "";
      if(!teamFormInput.org) {
        missingFields += "Volunteer Organization "
      }
      if(!teamFormInput.activity) {
        missingFields += "Volunteer Activity "
      }
      if(!teamFormInput.category) {
        missingFields += "Volunteer Activity Category "
      }
      setCreateTeamError("Missing required field: "+missingFields);
    } else if (ownedTeam.length > 0) {
      setCreateTeamError("You already created a Team. If you need to modify your Team, use the Edit Button (Gear Icon) next to the team select box.");
    } else {
      try { 
        teamFormInput.owner = userData['cognito:username'];
        teamFormInput.eventID = selectedTabId;
        let apiData = await API.graphql({ query: mutations.createTeam, variables: { input: teamFormInput } });
        if(updateForm) {
          await API.graphql({ query: mutations.updateVolunteer, variables: { input: {alias: userData['cognito:username'].split("_")[1], eventID: selectedTabId, 'volunteerType': 'team', 'teamID': apiData.data.createTeam.id}} });
        } else {
          let newFormInput = formInput;
          delete newFormInput.team;
          delete newFormInput['__typename'];
          newFormInput.eventID = selectedTabId;
          newFormInput.volunteerType = "team";
          newFormInput.teamID = apiData.data.createTeam.id;
          newFormInput.org = apiData.data.createTeam.org;
          newFormInput.category = apiData.data.createTeam.category;
          newFormInput.actvity = apiData.data.createTeam.actvity;
          newFormInput.eventDate = apiData.data.createTeam.eventDate;
          await API.graphql({ query: mutations.createVolunteer, variables: {input: newFormInput}});
        }
        setTeamList([...teamList,{"key":apiData.data.createTeam.id, "value": apiData.data.createTeam.id, "text": apiData.data.createTeam.name, "label": apiData.data.createTeam.name, "checked": 'on', "description": apiData.data.createTeam.description, "org":apiData.data.createTeam.org, "category": apiData.data.createTeam.category, "activity":apiData.data.createTeam.activity, "eventdate": apiData.data.createTeam.eventDate, "owner": userData['cognito:username']}]);
        setOpenPopup(false);
      } catch(e) {
        console.log(e);
      }
    }
  }

  async function createTeamStepUp() {
    const ownedTeam = (teamList.filter((team) => team.owner === userData['cognito:username']));
    if (ownedTeam.length > 0) {
      setCreateTeamError("You already created a Team. If you need to modify your Team, use the Edit Button (Gear Icon) next to the team select box.");
    } else {
      try { 
        teamFormInput.eventID = selectedTabId;
        teamFormInput.owner = userData['cognito:username'];
        let apiData = await API.graphql({ query: mutations.createTeam, variables: { input: teamFormInput } });
        if(updateForm) {
          await API.graphql({ query: mutations.updateVolunteer, variables: { input: {alias: userData['cognito:username'].split("_")[1], eventID: selectedTabId, 'volunteerType': 'team', 'teamID': apiData.data.createTeam.id}} });
        } else {
          let newFormInput = formInput;
          delete newFormInput.team;
          delete newFormInput['__typename'];
          newFormInput.eventID = selectedTabId;
          newFormInput.volunteerType = "team";
          newFormInput.teamID = apiData.data.createTeam.id;
          newFormInput.org = apiData.data.createTeam.org;
          newFormInput.category = apiData.data.createTeam.category;
          newFormInput.actvity = apiData.data.createTeam.actvity;
          newFormInput.eventDate = apiData.data.createTeam.eventDate;
          await API.graphql({ query: mutations.createVolunteer, variables: {input: newFormInput}});
        }
        setTeamList([...teamList,{"key":apiData.data.createTeam.id, "value": apiData.data.createTeam.id, "text": apiData.data.createTeam.name, "label": apiData.data.createTeam.name, "checked": 'on', "description": apiData.data.createTeam.description, "owner": userData['cognito:username']}]);
        setOpenPopup(false);
      } catch(e) {
        console.log(e);
      }
    }
  }
  
  function UserNotification() {
    return (
      <div>
        <EuiSpacer/>
        {formSuccess === 1 ? (
          <EuiCallOut title="Your entry has been updated or created!" color="success" iconType="user"/>
        ) : (
          <EuiCallOut title="Unable to update or create your entry, please contact the administrator." color="danger" iconType="user"/>
        )}
      </div>
    );
  }
  
  useEffect(() => {
    getTeamList();
    getVolunteer();
  }, [selectedTabId]);

  useEffect(async () => {
    await getVolunteer();
    if(teamID) {
      const currentSelectedTeam = (teamList.filter((team) => team.value === teamID));
      if(currentSelectedTeam.length === 1) {
        setFormInput({...formInput,volunteerType: "team", teamID: teamID});
        setSelectionValue(teamID);
      }
    }
  }, [teamList]);

  useEffect(()=>{
    setIsTeamOwner(false);
    const currentSelectedTeam = (teamList.filter((team) => team.value === selectionValue));
    if(currentSelectedTeam.length === 1) {
      if(currentSelectedTeam[0].owner === userData['cognito:username']) {
        setIsTeamOwner(true);
      }
    }
  },[selectionValue])

  useEffect(() => {
    if(tabID) {
      setSelectedTabId(tabID);
    } else if(!selectedTabId) {
      setSelectedTabId('StepUp2024');
    }
  }, []);

  useEffect(()=> {
    let teamSelected = teamList.filter((team) => team.value === selectionValue);
    if(teamSelected[0]) {
      let eventdate = teamSelected[0].eventdate;
      if(!eventdate) {
        eventdate = (moment().toISOString().split("T"))[0];
      }
      setFormInput({...formInput,org: teamSelected[0].org,activity: teamSelected[0].activity, category: teamSelected[0].category,eventDate: eventdate});
    }
  },[selectionValue,teamList]);
  
  const activityTypeCheckboxes = [
    {
      id: 'Walking',
      label: 'Walking'
    },
    {
      id: 'Running',
      label: 'Running'
    },
    {
      id: 'Cycling',
      label: 'Cycling'
    },
    {
      id: 'Hiking',
      label: 'Hiking'
    },
    {
      id: 'WorkOut',
      label: 'Working Out'
    },
    {
      id: 'Swimming',
      label: 'Swimming'
    },
    {
      id: 'Other',
      label: 'Other'
    }
  ];

  const [checkboxIdToSelectedMap, setCheckboxIdToSelectedMap] = useState({});

  const activtyOnChange = (optionId) => {
    const newCheckboxIdToSelectedMap = {
      ...checkboxIdToSelectedMap,
      ...{
        [optionId]: !checkboxIdToSelectedMap[optionId],
      },
    };
    let activityStringArr = [];
    for(let i in newCheckboxIdToSelectedMap) {
      if(newCheckboxIdToSelectedMap[i]) {
        activityStringArr.push(i);
      } 
    }
    let activityString = activityStringArr.join(",");
    setFormInput({...formInput,activityTypes: activityString});
  };

  const plannedUnitItems = [
    <EuiContextMenuItem key="plannedMiles" icon="visMapRegion" onClick={()=>{
      setFormInput({...formInput,plannedActivityUnit: "Miles"});
      setPlannedUnitOpen(false);
    }}>
      Miles
    </EuiContextMenuItem>,
    <EuiContextMenuItem key="plannedHours" icon="clock" onClick={()=>{
      setFormInput({...formInput,plannedActivityUnit: "Hours"});
      setPlannedUnitOpen(false);
    }}>
      Hours
    </EuiContextMenuItem>,
  ];

  const finalUnitItems = [
    <EuiContextMenuItem key="finalMiles" icon="visMapRegion" onClick={()=>{
      setFormInput({...formInput,finalActivityUnit: "Miles"});
      setFinalUnitOpen(false);
    }}>
      Miles
    </EuiContextMenuItem>,
    <EuiContextMenuItem key="finalHours" icon="clock" onClick={()=>{
      setFormInput({...formInput,finalActivityUnit: "Hours"});
      setFinalUnitOpen(false);
    }}>
      Hours
    </EuiContextMenuItem>,
  ];

  useEffect(()=>{
    let activityStringMap = {};
    if(formInput.activityTypes) {
      let activityStringArr = formInput.activityTypes.split(",");
      for(let i in activityStringArr) {
        activityStringMap[activityStringArr[i]] = true;
      }
      setCheckboxIdToSelectedMap(activityStringMap);
    } else {
      setCheckboxIdToSelectedMap({});
    }
  },[formInput]); 
  
  return (
    <EuiPageTemplate
      restrictWidth="65%"
      pageHeader={{
        iconType: 'reportingApp',
        pageTitle: 'Sign-Up'
      }}>
      <EuiTabs>
        {/* <EuiTab
          onClick={() =>  {
            setSelectedTabId("Apr2024");
          }}
          isSelected={"Apr2024" === selectedTabId}
        >
          Volunteer Activity
        </EuiTab> */}
        <EuiTab
          onClick={() =>  setSelectedTabId("StepUp2024")}
          isSelected={"StepUp2024" === selectedTabId}
        >
          Move More Month
        </EuiTab>
        <EuiTab
          onClick={() =>  setSelectedTabId("CSMMoveMore2024")}
          isSelected={"CSMMoveMore2024" === selectedTabId}
        >
          Strategic CSMs Wellness initiative
        </EuiTab>
      </EuiTabs>
      <EuiSpacer/>
      {formSuccess > 0 && <UserNotification/>}
      { selectedTabId === "Apr2024" && <>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
          <img src={logo} alt="Logo" />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem grow={3}>
            <EuiPanel>
              <EuiTitle size="s"><h1>Volunteer Info</h1></EuiTitle>
              <EuiSpacer/>
              <EuiFormRow label="Name">
                <EuiFieldText name="name" value={formInput.name} readOnly={true}/>
              </EuiFormRow>
              <EuiSpacer/>
              <EuiCallOut title="Volunteer T-shirt" size="m" iconType="package">
                <p>
                  Order your free Volunteer T-shirt by visiting this <EuiLink target="_blank" href="https://www.costore.com/amazonvolunteer/productthumbnails.asp">link</EuiLink>.
                </p>
              </EuiCallOut>
              {/* <EuiSpacer/>
              <EuiCallOut title="Phonetool" size="m" iconType="package">
                <p>
                  Once you have marked the status of the activity to complete, you will receive the following Phonetool: 
                  <img src="phonetool.png"/>
                </p>
              </EuiCallOut> */}
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <EuiPanel>
              <EuiTitle size="s"><h1>Volunteering Details</h1></EuiTitle>
              <EuiCallOut title="Individual of Team?" size="m" iconType="help">
                <p>
                  Select whether you are participating with a team or as an individual. If you are signing up for an event sponsered by AWSI Volunteer Month, you should select the team here.
                </p>
              </EuiCallOut>
              <EuiSpacer/>
              <EuiFormRow label="Team/Individual" fullWidth>
                <EuiSelect
                  id="TeamIndividual"
                  options={[{value: "team", text: "Team"},{value: "one", text:"Individual"}]}
                  value={formInput.volunteerType}
                  onChange={(e) => {
                    setFormInput({...formInput,volunteerType: e.target.value, teamID: "NoTeam"});
                  }}
                />
              </EuiFormRow>
              <EuiSpacer/>
              {formInput.volunteerType === 'team' ? <><EuiFormRow label="Team" helpText="Select your team here. If your team is not listed, you can create it with the button the right." fullWidth>
                <>
                  <EuiFlexGroup gutterSize="xs">
                    <EuiFlexItem grow={9}>
                      <EuiSelect
                        id="TeamSelect"
                        options={teamList}
                        value={selectionValue}
                        hasNoInitialSelection={true}
                        onChange={(e) => {
                          setFormInput({...formInput,teamID: e.target.value});
                          setSelectionValue(e.target.value);
                        }}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={1}>
                      <EuiPopover
                        button={<EuiButtonIcon aria-label="Add Team" iconType="listAdd" display="fill" size="m"
                          onClick={()=>{
                            if(!openPopup) {
                              setOpenPopup(true);
                              setCreateTeamError("");
                              setTeamFormInput(defaultTeamObject);
                            } else {
                              setOpenPopup(false);
                            }
                          }}
                        />}
                        isOpen={openPopup}
                        closePopover={()=>{setOpenPopup(false)}}
                      >
                        <EuiPanel style={{ width: 400 }}>
                          <EuiTitle size="s"><h1>Create Team</h1></EuiTitle>
                          <EuiFormRow label="Team Name">
                            <EuiFieldText name="teamName" value={teamFormInput.name}
                            onChange={(e)=>{
                              setTeamFormInput({...teamFormInput,name: e.target.value});
                            }}/>
                          </EuiFormRow>
                          <EuiSpacer/>
                          <EuiFormRow label="Description">
                            <EuiFieldText name="teamDesc" value={teamFormInput.description} 
                            onChange={(e)=>{
                              setTeamFormInput({...teamFormInput,description: e.target.value});
                            }}/>
                          </EuiFormRow>
                          <EuiSpacer/>
                          <EuiFormRow label="Volunteer Organization Name">
                            <EuiFieldText 
                            name="organization" 
                            value={teamFormInput.org}
                            onChange={(e)=>{
                              setTeamFormInput({...teamFormInput,org: e.target.value});
                            }}/>
                          </EuiFormRow>
                          <EuiFormRow label="Volunteer Activity">
                            <EuiFieldText 
                            name="activity" 
                            value={teamFormInput.activity}
                            onChange={(e)=>{
                              setTeamFormInput({...teamFormInput,activity: e.target.value});
                            }}/>
                          </EuiFormRow>
                          <EuiFormRow label="Volunteer Activity Category" helpText="Select the category that your volunteer activity falls under.">
                            <EuiSelect
                              id="category"
                              options={categoryOptions}
                              value={teamFormInput.category}
                              hasNoInitialSelection={true}
                              onChange={(e) => {
                                setTeamFormInput({...teamFormInput,category: e.target.value});
                              }}
                            />
                          </EuiFormRow>
                          <EuiFormRow label="Event Date" helpText="Do not fill this date if you do not have an activity planned. You can always change this later.">
                            <EuiDatePicker 
                              selected={moment(teamFormInput.eventDate, moment.ISO_8601)} 
                              onChange={(date)=> {
                                setTeamFormInput({...teamFormInput,eventDate: (date.toISOString().split("T"))[0]});
                              }} />
                          </EuiFormRow>
                          <EuiSpacer/>
                          <EuiButton fill onClick={()=> {createTeam();}}>
                            Create Team
                          </EuiButton>
                          <EuiSpacer/>
                          <>
                            {createTeamError && <EuiCallOut title={createTeamError} color="danger" iconType="user"/>}
                          </>
                        </EuiPanel>
                      </EuiPopover>
                    </EuiFlexItem>
                    {isTeamOwner && <>
                      <EuiFlexItem grow={1}>
                        <EuiPopover
                          button={<EuiButtonIcon aria-label="Edit Team" iconType="gear" display="fill" size="m"
                            onClick={()=>{
                              if(!openEditPopup) {
                                setTeamFormInput(defaultTeamObject);
                                let teamSelected = teamList.filter((team) => team.value === selectionValue);
                                if(teamSelected[0]) {
                                  setTeamFormInput({
                                    "id":teamSelected[0].value,
                                    "name":teamSelected[0].text,
                                    "description":teamSelected[0].description,
                                    "org":teamSelected[0].org,
                                    "activity": teamSelected[0].activity,
                                    "category": teamSelected[0].category,
                                    "eventDate": teamSelected[0].eventdate
                                  })
                                  setCreateTeamError("");
                                  setOpenEditPopup(true);
                                }
                              } else {
                                setOpenEditPopup(false);
                              }
                            }}
                          />}
                          isOpen={openEditPopup}
                          closePopover={()=>{setOpenEditPopup(false)}}
                        >
                          <EuiPanel style={{ width: 400 }}>
                            <EuiTitle size="s"><h1>Edit Team</h1></EuiTitle>
                            <EuiFormRow label="Team Name">
                              <EuiFieldText name="teamName" value={teamFormInput.name}
                              onChange={(e)=>{
                                setTeamFormInput({...teamFormInput,name: e.target.value});
                              }}/>
                            </EuiFormRow>
                            <EuiSpacer/>
                            <EuiFormRow label="Description">
                              <EuiFieldText name="teamDesc" value={teamFormInput.description} 
                              onChange={(e)=>{
                                setTeamFormInput({...teamFormInput,description: e.target.value});
                              }}/>
                            </EuiFormRow>
                            <EuiSpacer/>
                            <EuiFormRow label="Volunteer Organization Name">
                              <EuiFieldText 
                              name="organization" 
                              value={teamFormInput.org}
                              onChange={(e)=>{
                                setTeamFormInput({...teamFormInput,org: e.target.value});
                              }}/>
                            </EuiFormRow>
                            <EuiFormRow label="Volunteer Activity">
                              <EuiFieldText 
                              name="activity" 
                              value={teamFormInput.activity}
                              onChange={(e)=>{
                                setTeamFormInput({...teamFormInput,activity: e.target.value});
                              }}/>
                            </EuiFormRow>
                            <EuiFormRow label="Volunteer Activity Category" helpText="Select the category that your volunteer activity falls under.">
                              <EuiSelect
                                id="category"
                                options={categoryOptions}
                                value={teamFormInput.category}
                                hasNoInitialSelection={true}
                                onChange={(e) => {
                                  setTeamFormInput({...teamFormInput,category: e.target.value});
                                }}
                              />
                            </EuiFormRow>
                            <EuiFormRow label="Event Date" helpText="Do not fill this date if you do not have an activity planned. You can always change this later.">
                              <EuiDatePicker 
                                selected={moment(teamFormInput.eventDate, moment.ISO_8601)} 
                                onChange={(date)=> {
                                  setTeamFormInput({...teamFormInput,eventDate: (date.toISOString().split("T"))[0]});
                                }} />
                            </EuiFormRow>
                            <EuiSpacer/>
                            <EuiButton fill onClick={()=> {editTeam();}}>
                              Edit Team
                            </EuiButton>
                            <EuiSpacer/>
                            {createTeamError && <EuiCallOut title={createTeamError} color="danger" iconType="user"/>}
                          </EuiPanel>
                        </EuiPopover>
                      </EuiFlexItem>
                      <EuiFlexItem grow={1}>
                        <EuiPopover
                          button={<EuiButtonIcon aria-label="Add Team Members" iconType="users" display="fill" size="m"
                            onClick={()=>{
                              if(!openAddMemberPopup) {
                                setOpenAddMemberPopup(true);
                              } else {
                                setOpenAddMemberPopup(false);
                              }
                            }}
                          />}
                          isOpen={openAddMemberPopup}
                          closePopover={()=>{setOpenAddMemberPopup(false)}}
                        >
                          <EuiPanel style={{ width: 400 }}>
                            <EuiTitle size="s"><h1>List of Team Members</h1></EuiTitle>
                            <EuiTextArea
                              placeholder=""
                              value={addTeamMembersInput}
                              onChange={(e) => {
                                setAddTeamMembersInput(e.target.value);
                              }}
                            />
                            <EuiSpacer/>
                            <EuiButton fill onClick={()=> {addTeamMembers();}}>
                              Add Team Members
                            </EuiButton>
                            <EuiSpacer/>
                            {createTeamError && <EuiCallOut title={createTeamError} color="danger" iconType="user"/>}
                          </EuiPanel>
                        </EuiPopover>
                      </EuiFlexItem>
                    </>
                    }
                    <EuiFlexItem>
                      <EuiPopover
                        button={<EuiButtonIcon aria-label="Share Team" iconType="share" display="fill" size="m"
                          onClick={()=>{
                            setOpenSharePopup(true);
                          }}
                        />}
                          isOpen={openSharePopup}
                          closePopover={()=>{setOpenSharePopup(false)}}
                        >
                          <EuiCallOut title="Want to Share this Team/Event?" size="m" iconType="help">
                            <p>
                            Copy the below URL:<br/>
                            <a href={shareLink}>{shareLink}</a>
                            </p>
                          </EuiCallOut>
                        </EuiPopover>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </>
              </EuiFormRow>
              <EuiSpacer/>
              <EuiCallOut title="Not sure what to do?" size="m" iconType="help">
                <p>
                  As you are signing up with a team, the below fields are pre-filled and cannot be changed. If you are not participating in this event, please select Individual and fill out your Volunteer Details.
                </p>
              </EuiCallOut>
              </> : <>
              <EuiCallOut title="Not sure what to do?" size="m" iconType="help">
                <p>
                  Fill out the following and hit Submit:
                  <ul>
                    <li>Volunteer Organization Name</li>
                    <li>Volunteer Activity</li>
                    <li>Volunteer Activity Category</li>
                    <li>Event Date</li>
                  </ul>
                </p>
              </EuiCallOut>
              
              </>}
              
              <EuiSpacer/>
              <EuiFormRow label="Volunteer Organization Name" fullWidth>
                <EuiFieldText 
                name="organization" 
                value={formInput.org}
                onChange={(e)=>{
                  if(formInput.volunteerType !== 'team') {
                    setFormInput({...formInput,org: e.target.value});
                  }
                }}/>
              </EuiFormRow>
              <EuiFormRow label="Volunteer Activity" fullWidth>
                <EuiFieldText 
                name="activity" 
                value={formInput.activity}
                onChange={(e)=>{
                  if(formInput.volunteerType !== 'team') {
                    setFormInput({...formInput,activity: e.target.value});
                  }
                }}/>
              </EuiFormRow>
              <EuiFormRow label="Volunteer Activity Category" helpText="Select the category that your volunteer activity falls under." fullWidth>
                <EuiSelect
                  id="category"
                  options={categoryOptions}
                  value={formInput.category}
                  hasNoInitialSelection={true}
                  onChange={(e) => {
                    if(formInput.volunteerType !== 'team') {
                      setFormInput({...formInput,category: e.target.value});
                    }
                  }}
                />
              </EuiFormRow>
              <EuiFormRow label="Event Date" helpText="Do not fill this date if you do not have an activity planned. You can always change this later." fullWidth>
                <EuiDatePicker 
                  selected={moment(formInput.eventDate, moment.ISO_8601)} 
                  onChange={(date)=> {
                    if(formInput.volunteerType !== 'team') {
                      setFormInput({...formInput,eventDate: (date.toISOString().split("T"))[0]});
                    }
                  }} />
              </EuiFormRow>
              <EuiSpacer/>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <EuiPanel>
              <EuiTitle size="s"><h1>Volunteering Completion</h1></EuiTitle>
              <EuiFormRow label="Volunteering Status" error="You must upload a picture first before you can mark as complete." isInvalid={!fileUpload}>
              <EuiSelect
                id="VolunteeringStatus"
                readOnly={true}
                options={[{value: "planned", text: "Planned"},{value: "complete", text:"Complete"}]}
                value={formInput.volunteerStatus}
                onChange={(e) => {
                  if(fileUpload) {
                    setFormInput({...formInput,volunteerStatus: e.target.value});
                    console.log(e.target.value);
                  } 
                }}
              />
            </EuiFormRow>
            {isUploadInProgress &&<EuiCallOut title="File upload is in-progress." size="s" iconType="image"/>}
            <EuiFormRow label="Picture Upload">
              <EuiFilePicker
                multiple
                initialPromptText="Select or drag and drop your picture(s)."
                onChange={async (files) => {
                  setFileUpload(true);
                  try {
                    setIsUploadInProgress(true);
                    let filestr="";
                    for(let i in files) {
                      let ext = files[i].name.substr(files[i].name.lastIndexOf('.') + 1);
                      let filename = (userData['cognito:username'].split("_"))[1]+"-"+i+"."+ext;
                      if(ext ===  files[i].name) {
                        filename = (userData['cognito:username'].split("_"))[1]+"-"+i;
                      }
                      let results = await Storage.put(filename, files[i], {
                        contentType: files[i].type
                      });
                      filestr+=filename+","
                    }
                    formInput.volunteerStatus = 'complete';
                    formInput.fileKey = filestr;
                    setFormInput(formInput);
                  } catch (error) {
                    console.log('Error uploading file: ', error);
                  }   
                  setIsUploadInProgress(false);
                }}
                display="large"
              />
            </EuiFormRow>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>    
      </>}
      { selectedTabId === "StepUp2024" && <>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
          <img src={logo} alt="Logo" />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup grow={true}>
          <EuiFlexItem grow={3}>
            <EuiPanel>
              <EuiTitle size="s"><h1>Volunteer Info</h1></EuiTitle>
              <EuiSpacer/>
              <EuiFormRow label="Name">
                <EuiFieldText name="name" value={formInput.name} readOnly={true}/>
              </EuiFormRow>
              <EuiSpacer/>
              <EuiCallOut title="Volunteer T-shirt" size="m" iconType="package">
                <p>
                  Order your free Volunteer T-shirt by visiting this <EuiLink target="_blank" href="https://www.costore.com/amazonvolunteer/productthumbnails.asp">link</EuiLink>.
                </p>
              </EuiCallOut>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <EuiPanel>
              <EuiTitle size="s"><h1>Activity Details</h1></EuiTitle>
              <EuiSpacer/>
              <EuiFormRow label="Team/Individual" helpText="You can volunteer as part of a team. Teams will be ranked on our Leader Board." fullWidth>
                <EuiSelect
                  id="TeamIndividual"
                  options={[{value: "team", text: "Team"},{value: "one", text:"Individual"}]}
                  value={formInput.volunteerType}
                  hasNoInitialSelection={true}
                  onChange={(e) => {
                    setFormInput({...formInput,volunteerType: e.target.value, teamID: "NoTeam"});
                  }}
                />
              </EuiFormRow>
              {formInput.volunteerType === 'team' && <EuiFormRow label="Team" helpText="Select your team here. If your team is not listed, you can create it with the button the right." fullWidth>
                <>
                  <EuiFlexGroup gutterSize='xs'>
                    <EuiFlexItem grow={9}>
                      <EuiSelect
                        id="TeamSelect"
                        options={teamList}
                        value={selectionValue}
                        hasNoInitialSelection={true}
                        onChange={(e) => {
                          setFormInput({...formInput,teamID: e.target.value});
                          setSelectionValue(e.target.value);
                        }}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiPopover
                        button={<EuiButtonIcon aria-label="Add Team" iconType="listAdd" display="fill" size="m"
                          onClick={()=>{
                            if(!openPopup) {
                              setOpenPopup(true);
                            } else {
                              setOpenPopup(false);
                            }
                          }}
                        />}
                        isOpen={openPopup}
                        closePopover={()=>{setOpenPopup(false)}}
                      >
                        <EuiPanel style={{ width: 400 }}>
                          <EuiTitle size="s"><h1>Create Team</h1></EuiTitle>
                          <EuiFormRow label="Team Name">
                            <EuiFieldText name="teamName" value={teamFormInput.name}
                            onChange={(e)=>{
                              setTeamFormInput({...teamFormInput,name: e.target.value});
                            }}/>
                          </EuiFormRow>
                          <EuiSpacer/>
                          <EuiFormRow label="Description">
                            <EuiFieldText name="teamDesc" value={teamFormInput.description} 
                            onChange={(e)=>{
                              setTeamFormInput({...teamFormInput,description: e.target.value});
                            }}/>
                          </EuiFormRow>
                          <EuiSpacer/>
                          <EuiButton fill onClick={()=>{createTeamStepUp();}}>
                            Create Team
                          </EuiButton>
                          {createTeamError && <EuiCallOut title={createTeamError} color="danger" iconType="user"/>}
                        </EuiPanel>
                      </EuiPopover>
                    </EuiFlexItem>
                    {isTeamOwner && <>
                      <EuiFlexItem>
                        <EuiPopover
                          button={<EuiButtonIcon aria-label="Edit Team" iconType="gear" display="fill" size="m"
                            onClick={()=>{
                              if(!openEditPopup) {
                                setTeamFormInput(defaultTeamObject);
                                let teamSelected = teamList.filter((team) => team.value === selectionValue);
                                if(teamSelected[0]) {
                                  setTeamFormInput({
                                    "id":teamSelected[0].value,
                                    "name":teamSelected[0].text,
                                    "description":teamSelected[0].description
                                  })
                                  setCreateTeamError("");
                                  setOpenEditPopup(true);
                                }
                              } else {
                                setOpenEditPopup(false);
                              }
                            }}
                          />}
                          isOpen={openEditPopup}
                          closePopover={()=>{setOpenEditPopup(false)}}
                        >
                          <EuiPanel style={{ width: 400 }}>
                            <EuiTitle size="s"><h1>Edit Team</h1></EuiTitle>
                            <EuiFormRow label="Team Name">
                              <EuiFieldText name="teamName" value={teamFormInput.name}
                              onChange={(e)=>{
                                setTeamFormInput({...teamFormInput,name: e.target.value});
                              }}/>
                            </EuiFormRow>
                            <EuiSpacer/>
                            <EuiFormRow label="Description">
                              <EuiFieldText name="teamDesc" value={teamFormInput.description} 
                              onChange={(e)=>{
                                setTeamFormInput({...teamFormInput,description: e.target.value});
                              }}/>
                            </EuiFormRow>
                            <EuiSpacer/>
                            <EuiButton fill onClick={()=> {editTeam();}}>
                              Edit Team
                            </EuiButton>
                            <EuiSpacer/>
                            {createTeamError && <EuiCallOut title={createTeamError} color="danger" iconType="user"/>}
                          </EuiPanel>
                        </EuiPopover>
                      </EuiFlexItem>
                      <EuiFlexItem grow={1}>
                        <EuiPopover
                          button={<EuiButtonIcon aria-label="Add Team Members" iconType="users" display="fill" size="m"
                            onClick={()=>{
                              if(!openAddMemberPopup) {
                                setOpenAddMemberPopup(true);
                              } else {
                                setOpenAddMemberPopup(false);
                              }
                            }}
                          />}
                          isOpen={openAddMemberPopup}
                          closePopover={()=>{setOpenAddMemberPopup(false)}}
                        >
                          <EuiPanel style={{ width: 400 }}>
                            <EuiTitle size="s"><h1>List of Team Members</h1></EuiTitle>
                            <EuiTextArea
                              placeholder=""
                              value={addTeamMembersInput}
                              onChange={(e) => {
                                setAddTeamMembersInput(e.target.value);
                              }}
                            />
                            <EuiSpacer/>
                            <EuiButton fill onClick={()=> {addTeamMembers();}}>
                              Add Team Members
                            </EuiButton>
                            <EuiSpacer/>
                            {createTeamError && <EuiCallOut title={createTeamError} color="danger" iconType="user"/>}
                          </EuiPanel>
                        </EuiPopover>
                      </EuiFlexItem>
                    </>}
                    <EuiFlexItem>
                      <EuiPopover
                        button={<EuiButtonIcon aria-label="Share Team" iconType="share" display="fill" size="m"
                          onClick={()=>{
                            setOpenSharePopup(true);
                          }}
                        />}
                          isOpen={openSharePopup}
                          closePopover={()=>{setOpenSharePopup(false)}}
                        >
                          <EuiCallOut title="Want to Share this Team/Event?" size="m" iconType="help">
                            <p>
                            Copy the below URL:<br/>
                            <a href={shareLink}>{shareLink}</a>
                            </p>
                          </EuiCallOut>
                        </EuiPopover>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </>
              </EuiFormRow>}
              <EuiSpacer/>
              <EuiCallOut title="Planned vs Actual Activity" size="m" iconType="package">
                Input your planned activity (miles/hours) here. You can update your current miles/hours in the Activity Completion section. This can be done weekly or all at once at the end of the month.
              </EuiCallOut>
              <EuiFormRow label="Planned Monthly Activity (miles/hour)" helpText="You can put how many miles/hours you plan to be active for here.">
                <EuiFieldNumber
                  value={formInput.plannedActivityHours}
                  onChange={(e) => {
                    setFormInput({...formInput,plannedActivityHours: e.target.value});
                  }}
                  append={[
                    formInput.plannedActivityUnit,
                    <EuiPopover
                      button={<EuiButtonIcon iconType="arrowDown" aria-label="Popover" 
                        onClick={()=>{
                          setPlannedUnitOpen(true);
                        }}
                      />}
                      isOpen={plannedUnitOpen}
                      closePopover={() => {}}
                    >
                      <EuiContextMenuPanel size="s" items={plannedUnitItems} />
                    </EuiPopover>
                  ]}
                />
              </EuiFormRow>
              <EuiFormRow label="Activity Types" helpText="Select all that apply.">
                <EuiCheckboxGroup
                  options={activityTypeCheckboxes}
                  idToSelectedMap={checkboxIdToSelectedMap}
                  onChange={(id) => activtyOnChange(id)}
                />
              </EuiFormRow>
              <EuiFormRow label="Charity Selection" helpText="Select which charity you would prefer to donate to.">
                <EuiSelect
                  id="CharitySelection"
                  hasNoInitialSelection={true}
                  options={[{value: "stjude", text: "St. Jude Children's Research Hospital"},{value: "savethechildren", text:"Save the Children"},{value: "redcrescent", text:"The Red Crescent/Turk Kizilay"}]}
                  value={formInput.charitySelection}
                  onChange={(e) => {
                    setFormInput({...formInput,charitySelection: e.target.value});
                    console.log(e.target.value);
                  }}
                />
              </EuiFormRow>
              <EuiCallOut title="More Info about the Charities:" size="s" iconType="package">
                <ul>
                  <li><a href="https://www.stjude.org/about-st-jude/why-support-st-jude.html?sc_icid=us-mm-support">St. Jude Children's Research Hospital</a></li>
                  <li><a href="https://www.savethechildren.org/us/about-us/why-save-the-children">Save the Children</a></li>
                  <li><a href="http://the%20red%20crescent/Turk%20Kizilay%20:%20The%20Red%20Crescent%20(Red%20Cross)%20is%20on%20the%20front%20line%20responding%20to%20initial%20needs%20of%20the%20communities%20and%20Amazon%20is%20supporting%20the%20Red%20Crescent%20in%20these%20efforts.%20Learn%20more%20about%20the%20Red%20Crescent%E2%80%99s%20disaster%20relief%20here%20-%20https://www.kizilay.org.tr/what-we-do/national-disaster-management">The Red Crescent/Turk Kizilay</a></li>
                </ul>
              </EuiCallOut>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <EuiPanel>
              <EuiTitle size="s"><h1>Activity Completion</h1></EuiTitle>
              <EuiSpacer/>
              <EuiFormRow label="Activity Status" error={statusError} isInvalid={statusError !== ""}>
                <EuiSelect
                  id="ActivityStatus"
                  readOnly={true}
                  options={[{value: "planned", text: "Planned"},{value: "inprogress", text:"In-Progress"},{value: "complete", text:"Complete"},{value: "cancelled", text:"Cancelled"}]}
                  value={formInput.volunteerStatus}
                  onChange={(e) => {
                    setStatusError("");
                    if(fileUpload && e.target.value === "complete") {
                      setFormInput({...formInput,volunteerStatus: "complete"});
                      console.log(e.target.value);
                    }  else if(e.target.value !== "complete") {
                      setFormInput({...formInput,volunteerStatus: e.target.value});
                    } else if(!fileUpload && e.target.value === "complete") {
                      setStatusError("You must upload a picture first before you can mark as complete.");
                    }
                  }}
                />
              </EuiFormRow>
              <EuiSpacer/>
              <EuiCallOut title="Current Activity" size="m" iconType="package">
                Ensure that you are entering the total current miles/hours that you have completed for the Move More Month here.
              </EuiCallOut>
              <EuiFormRow label="Current Activity (miles/hour)">
                <EuiFieldNumber
                  value={formInput.finalActivityHours}
                  onChange={(e) => {
                    setFormInput({...formInput,finalActivityHours: e.target.value});
                  }}
                  append={[
                    formInput.finalActivityUnit,
                    <EuiPopover
                      button={<EuiButtonIcon iconType="arrowDown" aria-label="Popover" 
                        onClick={()=>{
                          setFinalUnitOpen(true);
                        }}
                      />}
                      isOpen={finalUnitOpen}
                      closePopover={() => {}}
                    >
                      <EuiContextMenuPanel size="s" items={finalUnitItems} />
                    </EuiPopover>
                  ]}
                />
              </EuiFormRow>
              {isUploadInProgress &&<EuiCallOut title="File upload is in-progress." size="s" iconType="image"/>}
              <EuiSpacer/>
              <EuiCallOut title="Type of Picture" size="m" iconType="package">
                A picture in your Volunteer T-shirt after a run or workout is preferred.
              </EuiCallOut>
              <EuiFormRow label="Picture Upload">
                <EuiFilePicker
                  multiple
                  initialPromptText="Select or drag and drop your picture(s)."
                  onChange={async (files) => {
                    setFileUpload(true);
                    try {
                      setIsUploadInProgress(true);
                      let filestr="";
                      for(let i in files) {
                        let ext = files[i].name.substr(files[i].name.lastIndexOf('.') + 1);
                        let filename = (userData['cognito:username'].split("_"))[1]+"-"+i+"."+ext;
                        if(ext ===  files[i].name) {
                          filename = (userData['cognito:username'].split("_"))[1]+"-"+i;
                        }
                        let results = await Storage.put(filename, files[i], {
                          contentType: files[i].type
                        });
                        filestr+=filename+","
                      }
                      formInput.volunteerStatus = 'complete';
                      formInput.fileKey = filestr;
                      setFormInput(formInput);
                    } catch (error) {
                      console.log('Error uploading file: ', error);
                    }   
                    setIsUploadInProgress(false);
                  }}
                  display="large"
                />
              </EuiFormRow>
              {formSuccess > 0 && <UserNotification/>}
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </>}
      { selectedTabId === "CSMMoveMore2024" && <>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
          <img src={movemorelogo} alt="Logo" />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup grow={true}>
          <EuiFlexItem grow={3}>
            <EuiPanel>
              <EuiTitle size="s"><h1>Info</h1></EuiTitle>
              <EuiSpacer/>
              <EuiFormRow label="Name">
                <EuiFieldText name="name" value={formInput.name} readOnly={true}/>
              </EuiFormRow>
              <EuiSpacer/>
              <EuiCallOut title="T-shirt" size="m" iconType="package">
                <p>
                  Order your free T-shirt by visiting this <EuiLink target="_blank" href="https://www.costore.com/amazonvolunteer/productthumbnails.asp">link</EuiLink>.
                </p>
              </EuiCallOut>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <EuiPanel>
              <EuiTitle size="s"><h1>Activity Details</h1></EuiTitle>
              <EuiSpacer/>
              <EuiFormRow label="Team/Individual" helpText="You can participate as part of a team. Teams will be ranked on our Leader Board." fullWidth>
                <EuiSelect
                  id="TeamIndividual"
                  options={[{value: "team", text: "Team"},{value: "one", text:"Individual"}]}
                  value={formInput.volunteerType}
                  hasNoInitialSelection={true}
                  onChange={(e) => {
                    setFormInput({...formInput,volunteerType: e.target.value, teamID: "NoTeam"});
                  }}
                />
              </EuiFormRow>
              {formInput.volunteerType === 'team' && <EuiFormRow label="Team" helpText="Select your team here. If your team is not listed, you can create it with the button the right." fullWidth>
                <>
                  <EuiFlexGroup gutterSize='xs'>
                    <EuiFlexItem grow={9}>
                      <EuiSelect
                        id="TeamSelect"
                        options={teamList}
                        value={selectionValue}
                        hasNoInitialSelection={true}
                        onChange={(e) => {
                          setFormInput({...formInput,teamID: e.target.value});
                          setSelectionValue(e.target.value);
                        }}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiPopover
                        button={<EuiButtonIcon aria-label="Add Team" iconType="listAdd" display="fill" size="m"
                          onClick={()=>{
                            if(!openPopup) {
                              setOpenPopup(true);
                            } else {
                              setOpenPopup(false);
                            }
                          }}
                        />}
                        isOpen={openPopup}
                        closePopover={()=>{setOpenPopup(false)}}
                      >
                        <EuiPanel style={{ width: 400 }}>
                          <EuiTitle size="s"><h1>Create Team</h1></EuiTitle>
                          <EuiFormRow label="Team Name">
                            <EuiFieldText name="teamName" value={teamFormInput.name}
                            onChange={(e)=>{
                              setTeamFormInput({...teamFormInput,name: e.target.value});
                            }}/>
                          </EuiFormRow>
                          <EuiSpacer/>
                          <EuiFormRow label="Description">
                            <EuiFieldText name="teamDesc" value={teamFormInput.description} 
                            onChange={(e)=>{
                              setTeamFormInput({...teamFormInput,description: e.target.value});
                            }}/>
                          </EuiFormRow>
                          <EuiSpacer/>
                          <EuiButton fill onClick={()=>{createTeamStepUp();}}>
                            Create Team
                          </EuiButton>
                          {createTeamError && <EuiCallOut title={createTeamError} color="danger" iconType="user"/>}
                        </EuiPanel>
                      </EuiPopover>
                    </EuiFlexItem>
                    {isTeamOwner && <>
                      <EuiFlexItem>
                        <EuiPopover
                          button={<EuiButtonIcon aria-label="Edit Team" iconType="gear" display="fill" size="m"
                            onClick={()=>{
                              if(!openEditPopup) {
                                setTeamFormInput(defaultTeamObject);
                                let teamSelected = teamList.filter((team) => team.value === selectionValue);
                                if(teamSelected[0]) {
                                  setTeamFormInput({
                                    "id":teamSelected[0].value,
                                    "name":teamSelected[0].text,
                                    "description":teamSelected[0].description
                                  })
                                  setCreateTeamError("");
                                  setOpenEditPopup(true);
                                }
                              } else {
                                setOpenEditPopup(false);
                              }
                            }}
                          />}
                          isOpen={openEditPopup}
                          closePopover={()=>{setOpenEditPopup(false)}}
                        >
                          <EuiPanel style={{ width: 400 }}>
                            <EuiTitle size="s"><h1>Edit Team</h1></EuiTitle>
                            <EuiFormRow label="Team Name">
                              <EuiFieldText name="teamName" value={teamFormInput.name}
                              onChange={(e)=>{
                                setTeamFormInput({...teamFormInput,name: e.target.value});
                              }}/>
                            </EuiFormRow>
                            <EuiSpacer/>
                            <EuiFormRow label="Description">
                              <EuiFieldText name="teamDesc" value={teamFormInput.description} 
                              onChange={(e)=>{
                                setTeamFormInput({...teamFormInput,description: e.target.value});
                              }}/>
                            </EuiFormRow>
                            <EuiSpacer/>
                            <EuiButton fill onClick={()=> {editTeam();}}>
                              Edit Team
                            </EuiButton>
                            <EuiSpacer/>
                            {createTeamError && <EuiCallOut title={createTeamError} color="danger" iconType="user"/>}
                          </EuiPanel>
                        </EuiPopover>
                      </EuiFlexItem>
                      <EuiFlexItem grow={1}>
                        <EuiPopover
                          button={<EuiButtonIcon aria-label="Add Team Members" iconType="users" display="fill" size="m"
                            onClick={()=>{
                              if(!openAddMemberPopup) {
                                setOpenAddMemberPopup(true);
                              } else {
                                setOpenAddMemberPopup(false);
                              }
                            }}
                          />}
                          isOpen={openAddMemberPopup}
                          closePopover={()=>{setOpenAddMemberPopup(false)}}
                        >
                          <EuiPanel style={{ width: 400 }}>
                            <EuiTitle size="s"><h1>List of Team Members</h1></EuiTitle>
                            <EuiTextArea
                              placeholder=""
                              value={addTeamMembersInput}
                              onChange={(e) => {
                                setAddTeamMembersInput(e.target.value);
                              }}
                            />
                            <EuiSpacer/>
                            <EuiButton fill onClick={()=> {addTeamMembers();}}>
                              Add Team Members
                            </EuiButton>
                            <EuiSpacer/>
                            {createTeamError && <EuiCallOut title={createTeamError} color="danger" iconType="user"/>}
                          </EuiPanel>
                        </EuiPopover>
                      </EuiFlexItem>
                    </>}
                    <EuiFlexItem>
                      <EuiPopover
                        button={<EuiButtonIcon aria-label="Share Team" iconType="share" display="fill" size="m"
                          onClick={()=>{
                            setOpenSharePopup(true);
                          }}
                        />}
                          isOpen={openSharePopup}
                          closePopover={()=>{setOpenSharePopup(false)}}
                        >
                          <EuiCallOut title="Want to Share this Team/Event?" size="m" iconType="help">
                            <p>
                            Copy the below URL:<br/>
                            <a href={shareLink}>{shareLink}</a>
                            </p>
                          </EuiCallOut>
                        </EuiPopover>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </>
              </EuiFormRow>}
              <EuiSpacer/>
              <EuiCallOut title="Planned vs Actual Activity" size="m" iconType="package">
                Input your planned activity (miles/hours) here. You can update your current miles/hours in the Activity Completion section. This can be done weekly or all at once at the end of the month.
              </EuiCallOut>
              <EuiFormRow label="Planned Monthly Activity (miles/hour)" helpText="You can put how many miles/hours you plan to be active for here.">
                <EuiFieldNumber
                  value={formInput.plannedActivityHours}
                  onChange={(e) => {
                    setFormInput({...formInput,plannedActivityHours: e.target.value});
                  }}
                  append={[
                    formInput.plannedActivityUnit,
                    <EuiPopover
                      button={<EuiButtonIcon iconType="arrowDown" aria-label="Popover" 
                        onClick={()=>{
                          setPlannedUnitOpen(true);
                        }}
                      />}
                      isOpen={plannedUnitOpen}
                      closePopover={() => {}}
                    >
                      <EuiContextMenuPanel size="s" items={plannedUnitItems} />
                    </EuiPopover>
                  ]}
                />
              </EuiFormRow>
              <EuiFormRow label="Activity Types" helpText="Select all that apply.">
                <EuiCheckboxGroup
                  options={activityTypeCheckboxes}
                  idToSelectedMap={checkboxIdToSelectedMap}
                  onChange={(id) => activtyOnChange(id)}
                />
              </EuiFormRow>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <EuiPanel>
              <EuiTitle size="s"><h1>Activity Completion</h1></EuiTitle>
              <EuiSpacer/>
              <EuiFormRow label="Activity Status" error={statusError} isInvalid={statusError !== ""}>
                <EuiSelect
                  id="ActivityStatus"
                  readOnly={true}
                  options={[{value: "planned", text: "Planned"},{value: "inprogress", text:"In-Progress"},{value: "complete", text:"Complete"},{value: "cancelled", text:"Cancelled"}]}
                  value={formInput.volunteerStatus}
                  onChange={(e) => {
                    setStatusError("");
                    if(fileUpload && e.target.value === "complete") {
                      setFormInput({...formInput,volunteerStatus: "complete"});
                      console.log(e.target.value);
                    }  else if(e.target.value !== "complete") {
                      setFormInput({...formInput,volunteerStatus: e.target.value});
                    } else if(!fileUpload && e.target.value === "complete") {
                      setStatusError("You must upload a picture first before you can mark as complete.");
                    }
                  }}
                />
              </EuiFormRow>
              <EuiSpacer/>
              <EuiCallOut title="Current Activity" size="m" iconType="package">
                Ensure that you are entering the total current miles/hours that you have completed for the Move More Month here.
              </EuiCallOut>
              <EuiFormRow label="Current Activity (miles/hour)">
                <EuiFieldNumber
                  value={formInput.finalActivityHours}
                  onChange={(e) => {
                    setFormInput({...formInput,finalActivityHours: e.target.value});
                  }}
                  append={[
                    formInput.finalActivityUnit,
                    <EuiPopover
                      button={<EuiButtonIcon iconType="arrowDown" aria-label="Popover" 
                        onClick={()=>{
                          setFinalUnitOpen(true);
                        }}
                      />}
                      isOpen={finalUnitOpen}
                      closePopover={() => {}}
                    >
                      <EuiContextMenuPanel size="s" items={finalUnitItems} />
                    </EuiPopover>
                  ]}
                />
              </EuiFormRow>
              {isUploadInProgress &&<EuiCallOut title="File upload is in-progress." size="s" iconType="image"/>}
              <EuiSpacer/>
              <EuiCallOut title="Type of Picture" size="m" iconType="package">
                A picture in your Volunteer T-shirt after a run or workout is preferred.
              </EuiCallOut>
              <EuiFormRow label="Picture Upload">
                <EuiFilePicker
                  multiple
                  initialPromptText="Select or drag and drop your picture(s)."
                  onChange={async (files) => {
                    setFileUpload(true);
                    try {
                      setIsUploadInProgress(true);
                      let filestr="";
                      for(let i in files) {
                        let ext = files[i].name.substr(files[i].name.lastIndexOf('.') + 1);
                        let filename = (userData['cognito:username'].split("_"))[1]+"-"+i+"."+ext;
                        if(ext ===  files[i].name) {
                          filename = (userData['cognito:username'].split("_"))[1]+"-"+i;
                        }
                        let results = await Storage.put(filename, files[i], {
                          contentType: files[i].type
                        });
                        filestr+=filename+","
                      }
                      formInput.volunteerStatus = 'complete';
                      formInput.fileKey = filestr;
                      setFormInput(formInput);
                    } catch (error) {
                      console.log('Error uploading file: ', error);
                    }   
                    setIsUploadInProgress(false);
                  }}
                  display="large"
                />
              </EuiFormRow>
              {formSuccess > 0 && <UserNotification/>}
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </>}
      <EuiFlexGroup>
        <EuiFlexItem grow={4}><EuiSpacer/></EuiFlexItem>
        <EuiFlexItem grow={4}><EuiSpacer/></EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiButton 
            isDisabled={isUploadInProgress}
            fill 
            onClick={async ()=>{
              let newFormInput = formInput;
              delete newFormInput.team;
              delete newFormInput['__typename'];
              newFormInput.eventID = selectedTabId;
              if(updateForm) {
                try {
                  await API.graphql({ query: mutations.updateVolunteer, variables: {input: newFormInput}});
                  setFormSuccess(1);
                } catch(e) {
                  console.log(e);
                  setFormSuccess(2);
                }
              } else {
                try {
                  await API.graphql({ query: mutations.createVolunteer, variables: {input: newFormInput}});
                  setFormSuccess(1);
                  setUpdateForm(true);
                } catch(e) {
                  console.log(e);
                  setFormSuccess(2);
                }
              }
            }}
          >
            Submit
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>    
    </EuiPageTemplate>
  );
}

function LeaderBoard({leaderBoard, selectedTabId, setSelectedTabId}) {
  const defaultColumns = [
    {
      name: 'Team Name',
      selector: row => row.name,
      sortable: true,
      maxWidth: '20%'
    },
    {
      name: 'Volunteers',
      selector: row => row.cnt,
      sortable: true,
      maxWidth: '20%'
    },
    {
      name: 'Description',
      selector: row => row.description,
      sortable: true,
      maxWidth: '60%'
    }
  ];

  const [sortedLeaderBoard, setSortedLeaderBoard] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [columns, setColumns] = useState(defaultColumns);

  const stepUpColumns = [
    {
      name: 'Team Name',
      selector: row => row.name,
      sortable: true,
      maxWidth: '30%'
    },
    {
      name: 'Volunteers',
      selector: row => row.cnt,
      sortable: true,
      maxWidth: '30%'
    },
    {
      name: 'Description',
      selector: row => row.description,
      sortable: true,
      maxWidth: '20%'
    },
    {
      name: 'Final Hours/Miles',
      selector: row => row.hours,
      sortable: true,
      maxWidth: '20%'
    }
  ];

  const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
		  if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
		  }
		};
		return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} colName='Team Name'/>;
	}, [filterText, resetPaginationToggle]);

  useEffect(() => {
    let sortable = [];
    for (let i in leaderBoard) {
      if (leaderBoard[i].eventID === selectedTabId) {
        sortable.push(leaderBoard[i]);
      }
    }
    
    sortable.sort(function(a, b) {
        return b.cnt - a.cnt;
    });

    setSortedLeaderBoard(sortable);
  }, [leaderBoard, selectedTabId]);


  return (
    <EuiPageTemplate
      restrictWidth="75%"
      pageHeader={{
        iconType: 'advancedSettingsApp',
        pageTitle: 'Leader Board'
      }}>
      <EuiTabs>
        {/* <EuiTab
          onClick={() =>  {
            setSelectedTabId("Apr2024");
            setColumns(defaultColumns);
          }}
          isSelected={"Apr2024" === selectedTabId}
        >
          Volunteer Activity
        </EuiTab> */}
        <EuiTab
          onClick={() => {
            setSelectedTabId("StepUp2024");
            setColumns(stepUpColumns);
          }}
          isSelected={"StepUp2024" === selectedTabId}
        >
          Move More Month
        </EuiTab>
        <EuiTab
          onClick={() =>  setSelectedTabId("CSMMoveMore2024")}
          isSelected={"CSMMoveMore2024" === selectedTabId}
        >
          Strategic CSMs Wellness initiative
        </EuiTab>
      </EuiTabs>
      <DataTable
              noHeader
              title=''
              columns={columns}
              data={sortedLeaderBoard}
              striped
              dense
              pagination
              paginationPerPage='25'
              paginationResetDefaultPage={resetPaginationToggle}
              subHeader
              subHeaderAlign="left"
              subHeaderComponent={subHeaderComponentMemo}
              />
      </EuiPageTemplate>
  );
}

function MainView({userData, isAdmin, volunteers, setVolunteers, leaderBoard}) {
  const [activeMenu, setActiveMenu] = useState("Sign-Up");
  const [selectedTabId,setSelectedTabId] = useState("");
  
  return (
    <div>
      <EuiHeader>
        <EuiHeaderSectionItem>
          <EuiHeaderLogo iconType="heartbeatApp">AWSI Volunteer</EuiHeaderLogo>
          <EuiHeaderLinks>
            <EuiHeaderLink>
              <a href="https://w.amazon.com/bin/view/Stratvolunteers" target="_blank" rel="noreferrer">AWSI Volunteer Homepage</a>
            </EuiHeaderLink>
            <EuiHeaderLink isActive={activeMenu === "Sign-Up"} onClick={()=> {
              setActiveMenu("Sign-Up");
            }}>Sign-Up</EuiHeaderLink>
            <EuiHeaderLink isActive={activeMenu === "Leader Board"} onClick={()=> {
              setActiveMenu("Leader Board");
            }}>Leader Board</EuiHeaderLink>
            <EuiHeaderLink isActive={activeMenu === "Volunteer List"} onClick={()=> {
              setActiveMenu("Volunteer List");
            }}>Volunteer List</EuiHeaderLink>
            {isAdmin && <EuiHeaderLink isActive={activeMenu === "Admin"} onClick={()=> {
              setActiveMenu("Admin");
            }}>Admin</EuiHeaderLink>}
          </EuiHeaderLinks>
        </EuiHeaderSectionItem>
      </EuiHeader>
      {activeMenu === "Sign-Up" && <SignUp userData={userData} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId}/>}
      {activeMenu === "Leader Board" && <LeaderBoard leaderBoard={leaderBoard} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId}/>}
      {activeMenu === "Volunteer List" && <VolunteerList volunteers={volunteers} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId}/>}
      {activeMenu === "Admin" && <Admin volunteers={volunteers} setVolunteers={setVolunteers} selectedTabId={selectedTabId}/>}
    </div>
  );
}


function App() {
  const [userData, setUserData] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [volunteers, setVolunteers] = useState([]);
  const [leaderBoard,setLeaderBoard] = useState({});

  async function getVolunteers(localIsAdmin) {
    let volunteerList = [];
    let _leaderBoard = {};
    let listVolunteersQuery = `
    query ListVolunteers(
      $filter: ModelVolunteerFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listVolunteers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          name
          email
          alias
          amznLoc
          tshirtSize
          volunteerStatus
          volunteerType
          org
          activity
          eventDate
          tshirtShipped
          phonetoolGiven
          fileKey
          createdAt
          updatedAt
          owner
          createdAt
          eventID
          teamID
          team {
            name
          }
        }
        nextToken
      }
    }
    `;
    
    if(localIsAdmin) {
      listVolunteersQuery = queries.listVolunteers;
    }
    let eventIDfilter = {
      or: [
        {
          eventID: {
              eq: "Apr2024"
          }
        },
        {
          eventID: {
              eq: "StepUp2024"
          }
        },
        {
          eventID: {
              eq: "CSMMoveMore2024"
          }
        },
      ]
    };
    let results = await API.graphql({ query: listVolunteersQuery, variables: {limit: 1000, filter: eventIDfilter}});
    for(let i in results.data.listVolunteers.items) {
      if(results.data.listVolunteers.items[i].team) {
        if(!_leaderBoard[results.data.listVolunteers.items[i].team.name]) {
          _leaderBoard[results.data.listVolunteers.items[i].team.name] = results.data.listVolunteers.items[i].team;
        }
        if(!_leaderBoard[results.data.listVolunteers.items[i].team.name].cnt) {
          _leaderBoard[results.data.listVolunteers.items[i].team.name].cnt = 0;
          _leaderBoard[results.data.listVolunteers.items[i].team.name].hours = 0;
          _leaderBoard[results.data.listVolunteers.items[i].team.name].eventID = results.data.listVolunteers.items[i].eventID;
        }
        _leaderBoard[results.data.listVolunteers.items[i].team.name].hours += results.data.listVolunteers.items[i].finalActivityHours;
        _leaderBoard[results.data.listVolunteers.items[i].team.name].cnt++;
      }
    }
    volunteerList = volunteerList.concat(results.data.listVolunteers.items);
    while(results.data.listVolunteers.nextToken) {
      results = await API.graphql({ query: listVolunteersQuery, variables: {limit: 1000, filter: eventIDfilter, nextToken: results.data.listVolunteers.nextToken}});
      volunteerList = volunteerList.concat(results.data.listVolunteers.items);
    }
    setVolunteers(volunteerList);
    setLeaderBoard(_leaderBoard);
  }
  
  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(user => user)
      .catch(() => {
        console.log('Not signed in');
        Auth.federatedSignIn();
      });
  }
  
  useEffect(() => {
    getUser().then((user) => {
      let localIsAdmin = false;
      setUserData(user.signInUserSession.idToken.payload);
      for(let i in user.signInUserSession.idToken.payload['cognito:groups']) {
        if(user.signInUserSession.idToken.payload['cognito:groups'][i] === "Admins") {
          setIsAdmin(true);
          localIsAdmin = true;
        }
      }
      getVolunteers(localIsAdmin);
    });
  }, []);
  
  return (
    <div className="App">
      {userData ? (
        <MainView userData={userData} isAdmin={isAdmin} volunteers={volunteers} setVolunteers={setVolunteers} leaderBoard={leaderBoard}/>
      ) : (
        <EuiFlexGroup justifyContent="spaceAround">
          <EuiFlexItem grow={false}>
          <EuiTitle size="l">
						<h2>Authenticating with Amazon Federate, Please Wait...</h2>
					</EuiTitle>
          <EuiSpacer/>
          <EuiButton fill onClick={()=>{
            logOut();
          }}>
					Re-Login</EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </div>
  );
}

export default App;
