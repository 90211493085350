/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVolunteer = /* GraphQL */ `
  query GetVolunteer($alias: String!, $eventID: String!) {
    getVolunteer(alias: $alias, eventID: $eventID) {
      alias
      eventID
      name
      email
      amznLoc
      address1
      address2
      city
      state
      zipcode
      tshirtSize
      volunteerStatus
      volunteerType
      org
      activity
      plannedActivityHours
      finalActivityHours
      charitySelection
      activityTypes
      plannedActivityUnit
      finalActivityUnit
      category
      eventDate
      tshirtShipped
      phonetoolGiven
      fileKey
      country
      partySize
      teamID
      team {
        id
        name
        eventID
        description
        type
        teamMembers
        org
        activity
        category
        eventDate
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listVolunteers = /* GraphQL */ `
  query ListVolunteers(
    $alias: String
    $eventID: ModelStringKeyConditionInput
    $filter: ModelVolunteerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVolunteers(
      alias: $alias
      eventID: $eventID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        alias
        eventID
        name
        email
        amznLoc
        address1
        address2
        city
        state
        zipcode
        tshirtSize
        volunteerStatus
        volunteerType
        org
        activity
        plannedActivityHours
        finalActivityHours
        charitySelection
        activityTypes
        plannedActivityUnit
        finalActivityUnit
        category
        eventDate
        tshirtShipped
        phonetoolGiven
        fileKey
        country
        partySize
        teamID
        team {
          id
          name
          eventID
          description
          type
          teamMembers
          org
          activity
          category
          eventDate
          owner
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!, $eventID: String!) {
    getTeam(id: $id, eventID: $eventID) {
      id
      name
      eventID
      description
      type
      teamMembers
      org
      activity
      category
      eventDate
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $id: ID
    $eventID: ModelStringKeyConditionInput
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTeams(
      id: $id
      eventID: $eventID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        eventID
        description
        type
        teamMembers
        org
        activity
        category
        eventDate
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
