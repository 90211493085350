/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVolunteer = /* GraphQL */ `
  mutation CreateVolunteer(
    $input: CreateVolunteerInput!
    $condition: ModelVolunteerConditionInput
  ) {
    createVolunteer(input: $input, condition: $condition) {
      alias
      eventID
      name
      email
      amznLoc
      address1
      address2
      city
      state
      zipcode
      tshirtSize
      volunteerStatus
      volunteerType
      org
      activity
      plannedActivityHours
      finalActivityHours
      charitySelection
      activityTypes
      plannedActivityUnit
      finalActivityUnit
      category
      eventDate
      tshirtShipped
      phonetoolGiven
      fileKey
      country
      partySize
      teamID
      team {
        id
        name
        eventID
        description
        type
        teamMembers
        org
        activity
        category
        eventDate
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateVolunteer = /* GraphQL */ `
  mutation UpdateVolunteer(
    $input: UpdateVolunteerInput!
    $condition: ModelVolunteerConditionInput
  ) {
    updateVolunteer(input: $input, condition: $condition) {
      alias
      eventID
      name
      email
      amznLoc
      address1
      address2
      city
      state
      zipcode
      tshirtSize
      volunteerStatus
      volunteerType
      org
      activity
      plannedActivityHours
      finalActivityHours
      charitySelection
      activityTypes
      plannedActivityUnit
      finalActivityUnit
      category
      eventDate
      tshirtShipped
      phonetoolGiven
      fileKey
      country
      partySize
      teamID
      team {
        id
        name
        eventID
        description
        type
        teamMembers
        org
        activity
        category
        eventDate
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteVolunteer = /* GraphQL */ `
  mutation DeleteVolunteer(
    $input: DeleteVolunteerInput!
    $condition: ModelVolunteerConditionInput
  ) {
    deleteVolunteer(input: $input, condition: $condition) {
      alias
      eventID
      name
      email
      amznLoc
      address1
      address2
      city
      state
      zipcode
      tshirtSize
      volunteerStatus
      volunteerType
      org
      activity
      plannedActivityHours
      finalActivityHours
      charitySelection
      activityTypes
      plannedActivityUnit
      finalActivityUnit
      category
      eventDate
      tshirtShipped
      phonetoolGiven
      fileKey
      country
      partySize
      teamID
      team {
        id
        name
        eventID
        description
        type
        teamMembers
        org
        activity
        category
        eventDate
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      eventID
      description
      type
      teamMembers
      org
      activity
      category
      eventDate
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      eventID
      description
      type
      teamMembers
      org
      activity
      category
      eventDate
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      eventID
      description
      type
      teamMembers
      org
      activity
      category
      eventDate
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
